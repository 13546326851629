
import { Injectable } from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {

  //STAGING MVP
 // baseUrl = 'https://api.roca.bigcityvoucher.co.in/v1';

  //prod

 // baseUrl ='http://192.168.1.178/stashfinfullfilment/web/v1'
 // baseUrl ='http://192.168.1.178/stashfin_webappapi/web/v2';


//baseUrl ='https://api.stashfin.bigcityvoucher.co.in/v2';

 //prod
baseUrl='https://api.stashfin.bigcityexperience.com/v2';




  login = this.baseUrl + "/login/login";
  logout = this.baseUrl + "/hubadmin/logout";

  //company
  getCompanylist = this.baseUrl + "/hubadmin/list-company";
  addCompany = this.baseUrl + "/hubadmin/add-company";
  editCompany = this.baseUrl + "/hubadmin/edit-company";
  deleteCompany = this.baseUrl + "/hubadmin/deactivate-company";
  generateId = this.baseUrl + "/hubadmin/generate-id";

  orderDetails = this.baseUrl + '/client/getordercumulative'


  //programns

  addPrograms = this.baseUrl + "/hubadmin/add-programs";
  editPrograms = this.baseUrl + "/hubadmin/edit-programs";
  listPrograms = this.baseUrl + "/hubadmin/list-programs"
  deactivateProgram = this.baseUrl + "/hubadmin/deactivate-programs"

  // Product Category

  addCategory = this.baseUrl + "/hubadmin/add-product-cats"
  editCategory = this.baseUrl + "/hubadmin/edit-product-cats"
  listCategory = this.baseUrl + "/hubadmin/list-product-cats"
  deactivateCategory = this.baseUrl + "/hubadmin/deactivate-product-cats"

  //variation-masters 
  addVariationMas = this.baseUrl + "/hubadmin/add-variation-mas"
  listVariationMas = this.baseUrl + "/hubadmin/list-variation-mas"

  // variation
  addVariation = this.baseUrl + "/hubadmin/add-variation"
  listVariation = this.baseUrl + "/hubadmin/list-variation"

  // Products
  uploadProductFile = this.baseUrl + "/hubadmin/product-excel-upload"

  addProducts = this.baseUrl+"/hubadmin/add-product"

  // /Products-variation
  uploadProductVarFile = this.baseUrl + "/hubadmin/product-variation-upload"

  // ListOFProducts
  ListOfProducts = this.baseUrl + "/hubadmin/list-product"

  //delivery partner
  getdelPartnerList = this.baseUrl + "/delivery-partner/get-partner?";

  //Redemption Panel
  getOrderList = this.baseUrl + "/redemption/order-listing?";
  getListProgram = this.baseUrl + "/hubadmin/list-programs";
  uploadEGVOrderStatus = this.baseUrl + "/redemption/order-offer-code-upload";
  uploadPhysiaclOrderStatus = this.baseUrl + "/redemption/order-statusupload";
  rejectDropdown = this.baseUrl + "/redemption/rejection-reason"; // rejected reason
  approveRejectOrder = this.baseUrl + "/redemption/approve-rejectby-bcp";
  deliveryPartner = this.baseUrl + "/redemption/delivery-partner";



  changeOrderStatusByForm = this.baseUrl + "/ops-panel/bulk-status-update?program_id=1";
  changeOrderStatusByeExcel = this.baseUrl + "/ops-panel/order-statusupload?program_id=1";
  getDownOrderExlData = this.baseUrl + "/ops-panel/get-orderexcel";
  getCancelReasonDropDown = this.baseUrl + "/ops-panel/get-order-cancelling-reason";

  getVoucherCodes = this.baseUrl + "/redemption/offercode"

  // Reverse Points
  ReversePoints = this.baseUrl + "/redemption/reversal-panel";
  RedemptionReversePoint = this.baseUrl + "/redemption/reverse-point"
  // physical reward
  downloadEgvRewards = this.baseUrl + "/redemption/egv-summary"
  downloadPhysicalRewardExcel = this.baseUrl + "/redemption/order-summary";
  orderStatus = this.baseUrl + "/redemption/readorderstatus";
  deliveryPatner = this.baseUrl + "/redemption/delivery-partner";
  storeStatus = this.baseUrl + "/redemption/setorderstatus";

  //Finance
  getProformaInvoice = this.baseUrl + "/client/getperforma?";
  approveSingleInvoice = this.baseUrl + "/client/setapprovalstatus?new_approval_status=2";
  downloadSingleInvoice = this.baseUrl + "/finance/proforma-download?";
  uploadInvoiceStatus = this.baseUrl + "/hubadmin/upload-invoice";
  getInvoiceList = this.baseUrl + "/hubadmin/list-invoice?";
  generatePI = this.baseUrl + "/finance/generate-proforma";
  verifyInvoice = this.baseUrl + "/hubadmin/verify-invoice?invoice_id=";
  downloadProofZip = this.baseUrl + "/client/download-invoice-proofs?invoice_id=";
deliverOrders = this.baseUrl+'/finance/delivered-orders'

  //Agent Panel
  getAgentOrderList = this.baseUrl + "/report/customer-orders?";
  getSingleRedemptionInfo = this.baseUrl + "/redemption/offercode"


}
export interface ApiConfigurationInterface { }